import React from "react"
import ReactDOM from "react-dom"
import MenuList from "./MenuList"

import styled from "styled-components"

const StyledContainer = styled.aside`
  width: 100vw;
  height: 100dvh;
  position: fixed;
  top: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9998;
`
export default function Sidebar({ ref, isOpen, setIsOpen }) {
  if (typeof window === "undefined") return null
  if (isOpen) {
    // document.body.style.overflow = "visible"
    document.body.style.top = `-${window.scrollY}px`
    document.body.style.position = "fixed"
    document.body.style.width = "100%"
  } else {
    const scrollY = document.body.style.top
    document.body.style.position = ""
    document.body.style.top = ""
    window.scrollTo(0, parseInt(scrollY || "0") * -1)
    document.body.style.width = ""
  }
  if (isOpen) {
    return ReactDOM.createPortal(
      <StyledContainer ref={ref}>
        <MenuList isMenuListActive={isOpen} setIsMenuListActive={setIsOpen} />
      </StyledContainer>,
      document.querySelector("#___gatsby")
    )
  }
  return null
}

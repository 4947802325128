const theme = {
  colors: {
    primary: {
      black: `#000000`,
      brown: `#663100`,
      gray: `#E6E6E6`,
      green: `#008742`,
      red: `#E50012`,
      white: `#FFFFFF`,
    },
    light: {
      brown: `#E4D8B9`,
    },
    dark: {
      gray: `#C4C4C4`,
    },
  },
}

export default theme

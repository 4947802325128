import React, { useState, useContext } from "react"
import { Link } from "gatsby"
// import { useStaticQuery } from "gatsby"
import styled, { css } from "styled-components"
import { media } from "../styles/media"
// import CorpLogo2 from "../images/francoisLogoNonBorder.svg"
// import MenuList from "./MenuList"
import HeaderOverWrapper from "./HeaderOverWrapper"
import { HeaderOverWrappedContext } from "../hooks/HeaderOverWrappedContext"
import CorpLogo from "../images/francoisJPwhiteIn.svg"

// export const query = graphql`
//   {
//     strapiBrandAssets(logoTitle: { eq: "francoisWhiteJp" }) {
//       logoTitle
//       logoAsset {
//         relativePath
//       }
//     }
//   }
// `

const StyledWrapper = styled.div`
  height: 60px;
  width: 100%;
  padding: 0 5%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  z-index: 10;
`

const StyledTopIcon = styled.div`
  img {
    height: 25px;
    width: auto;
    ${() =>
      media.sp(css`
        height: 20px;
      `)}
  }
`
const StyledNavBtn = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-end;
  z-index: 1100;
  cursor: pointer;
  span {
    font-size: 12px;
    color: white;
  }
  #btnDesign {
    width: 50px;
    height: 18px;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    position: relative;
    justify-content: center;
    span {
      height: 2px;
      border-radius: 1px;
      width: 60%;
    }

    .btnTop {
      background: ${({ theme }) => theme.colors.primary.red};
      /* animation:toCircleRed .6s ease-out 0s alternate infinite; */

      /* @keyframes toCircleRed {
        from {}
        to{width:15px;height:15px;border-radius:15px;}
      } */
    }
    .btnBottom {
      background: ${({ theme }) => theme.colors.primary.green};
    }

    @keyframes toCircleRed {
      from {
        height: 2px;
        border-radius: 1px;
        width: 60%;
      }
      to {
        width: 18px;
        height: 18px;
        border-radius: 50%;
        position: absolute;
        left: 8px;
      }
    }
    @keyframes toCircleGreen {
      from {
        height: 2px;
        border-radius: 1px;
        width: 60%;
      }
      to {
        width: 18px;
        height: 18px;
        border-radius: 50%;
        position: absolute;
        left: 26px;
        bottom: 1px;
      }
    }
    @keyframes toCircleReverse {
      0% {
        width: 18px;
        height: 18px;
        border-radius: 50%;
      }

      to {
        height: 2px;
        border-radius: 1px;
        width: 60%;
      }
    }

    /* @keyframes toCircle {
      from {
      }
      to {
        width: 15px;
        height: 15px;
        border-radius: 15px;
      }
    } */
  }
`

const Header = ({ isSidebarOpen, setIsSidebarOpen }) => {
  // const [isMenuListActive, setIsMenuListActive] = useState(null)
  const [state] = useContext(HeaderOverWrappedContext)
  // React.useEffect(() => {
  //   console.log("isMenuListActive", isMenuListActive)
  //   if (isMenuListActive) {
  //     document.body.style.overflow = "hidden"
  //   } else {
  //     document.body.style.overflow = ""
  //   }
  // }, [isMenuListActive])

  // if (isMenuListActive)
  //   return (
  //     <MenuList
  //       isMenuListActive={isMenuListActive}
  //       setIsMenuListActive={setIsMenuListActive}
  //     />
  //   )

  return (
    <header>
      <StyledWrapper>
        <StyledTopIcon>
          <Link to="/">
            <img src={CorpLogo} alt="francois corporation logo" />
          </Link>
        </StyledTopIcon>
        {!isSidebarOpen && (
          <StyledNavBtn
            // onClick={() => setIsMenuListActive(!isMenuListActive)}
            // isActive={isMenuListActive}
            onClick={() => setIsSidebarOpen(!isSidebarOpen)}
            // isActive={isSidebarOpen}
          >
            {!state.isDisplay && <span>MENU</span>}
            <span id="btnDesign">
              <span className="btnTop"></span>
              <span className="btnBottom"></span>
            </span>
          </StyledNavBtn>
        )}

        {state.isDisplay && <HeaderOverWrapper />}
      </StyledWrapper>
    </header>
  )
}

export default Header

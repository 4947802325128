import React, { createContext, useState } from "react"

const HeaderOverWrappedContext = createContext([{}, () => {}])

const HeaderOverWrappedProvider = ({ children }) => {
  const [state, setState] = useState({})
  return (
    <HeaderOverWrappedContext.Provider value={[state, setState]}>
      {children}
    </HeaderOverWrappedContext.Provider>
  )
}

export { HeaderOverWrappedContext, HeaderOverWrappedProvider }

import { css } from "styled-components"

export const media = {
  tablet: styles => css`
    @media only screen and (max-width: 1100px) {
      ${styles}
    }
  `,
  sp: styles => css`
    @media only screen and (max-width: 600px) {
      ${styles}
    }
  `,
}

import React from "react"
import { Link } from "gatsby"
import styled, { css } from "styled-components"
import { media } from "../styles/media"
import CorpLogo2 from "../images/francoisLogoNonBorder.svg"

const StyledHeaderOverWrapper = styled.div`
  position: absolute;
  background: white;
  box-shadow: 0px 1px 0px#dadada;
  width: 100%;
  height: 100%;
  top: -60px;
  left: 0;
  animation: header-move-in 0.2s linear 0s forwards;
  z-index: 980;
  @keyframes header-move-in {
    from {
      transform: translateY(0);
    }
    to {
      transform: translateY(60px);
    }
  }
  padding: 0 5%;
  overflow: hidden;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  a img {
    height: 45px;
  }
  ${() => media.sp(css`
  a img{
    height:35px;
  }
  `
)}
`

const HeaderOverWrapper = () => (
  <StyledHeaderOverWrapper>
    <Link to="/">
      <img src={CorpLogo2} alt="francois logo" />
    </Link>
  </StyledHeaderOverWrapper>
)

export default HeaderOverWrapper

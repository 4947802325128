import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import Image from "gatsby-image"
import styled, { css } from "styled-components"
import { IconContext } from "react-icons"
import { BiCopyright } from "react-icons/bi"
import { FaExternalLinkAlt } from "react-icons/fa"
import { GrYoutube, GrFacebook } from "react-icons/gr"

import { media } from "../styles/media"
import FooterLogoImage from "../images/francoisLogoNonBorder.svg"

const FooterWrap = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  .footBackImg {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 0;
    opacity: 1;
    .gatsby-image-wrapper {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
  #footer {
    position: relative;
    background: transparent;
    padding: 100px 0 30px;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: flex-start;
    .footerTopLogo {
      width: 100%;
      margin-bottom: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 80px;
      }
    }
  }
  .snsSection {
    width: 100%;
    padding: 50px 0;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    svg {
      margin: 0 30px;
      transform: scale(1.6);
    }
  }
  .footerCopy {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    a {
      color: #dadada;
      font-size: 0.8em;
      margin-top: 0.62em;
      text-decoration: underline;
    }
    p {
      color: white;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      svg {
        margin-right: 0.62em;
      }
    }
  }
`

const FooterNav = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: flex-start;
  .footerNav1 {
    color: white;
    width: 30%;
    margin: 0 50px;
    display: flex;
    flex-flow: column nowrap;
    align-items: centner;
    h4 {
      padding: 10px 0 30px;
      font-weight: bold;
      color: white;
      svg {
        margin-left: 10px;
      }
    }
    a {
      margin: 8px 0;
    }
  }
  ${() =>
    media.sp(css`
      .footerNav1 {
        width: 90%;
        margin: 0 auto;
        align-items: center;
        margin-bottom: 50px;
        h4 {
          padding: 0px 0 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-flow: row wrap;
          &:after {
            display: block;
            content: "";
            width: 26%;
            height: 3px;
            border-radius: 5px;
            background: white;
            margin-top: 0.62em;
          }
        }
        a {
          font-size: 0.9em;
        }
      }
    `)}
`

const Footer = () => {
  const data = useStaticQuery(query)
  const {
    strapiFooterSection: {
      backgroundImage,
      footerNavigation,
      footerNavigation2,
      iconSet,
    },
  } = data

  return (
    <FooterWrap>
      <div className="footBackImg">
        <Image
          fluid={backgroundImage.childImageSharp.fluid}
          alt="footer image"
        />
      </div>
      <footer id="footer">
        <div className="footerTopLogo">
          <img src={FooterLogoImage} alt="logo on footer" />
        </div>
        <FooterNav>
          <div className="footerNav1">
            <h4>{footerNavigation.sectionTitle}</h4>
            {footerNavigation.nabigationSet.map(
              ({ nabiUrl, naviTitle }, index) => {
                if (nabiUrl !== "/allergen-search/")
                  return (
                    <Link to={nabiUrl} key={index}>
                      {naviTitle}
                    </Link>
                  )
              }
            )}
          </div>
          <div className="footerNav1">
            <h4>
              <a
                href="https://corporate.francois.co.jp/"
                rel="noopener noreferrer"
                target="_blank"
              >
                {footerNavigation2.sectionTitle}
                <FaExternalLinkAlt />
              </a>
            </h4>
            {footerNavigation2.nabigationSet.map(
              ({ nabiUrl, naviTitle }, index) => (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={nabiUrl}
                  key={index}
                >
                  {naviTitle}
                </a>
              )
            )}
          </div>
        </FooterNav>
        <div className="snsSection">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={iconSet[0].link2sns}
          >
            <IconContext.Provider value={{ color: "white" }}>
              <GrYoutube />
            </IconContext.Provider>
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={iconSet[1].link2sns}
          >
            <IconContext.Provider value={{ color: "white" }}>
              <GrFacebook />
            </IconContext.Provider>
          </a>
        </div>
        <div className="footerCopy">
          <p>
            <BiCopyright />
            2021 Francois Co., Ltd.
          </p>
          <Link to="/about-this-website">About Our Website</Link>
        </div>
      </footer>
    </FooterWrap>
  )
}

const query = graphql`
  {
    strapiFooterSection {
      backgroundImage {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      footerNavigation {
        sectionTitle
        nabigationSet {
          id
          nabiUrl
          naviTitle
        }
      }
      footerNavigation2 {
        sectionTitle
        nabigationSet {
          id
          nabiUrl
          naviTitle
        }
      }
      iconSet {
        id
        link2sns
      }
    }
  }
`

export default Footer

import React, { useState, useEffect } from "react"
import Helmet from "react-helmet"
import styled, { ThemeProvider } from "styled-components"
import theme from "../styles/theme"
import GlobalStyle from "../styles/globalStyle"
import Footer from "./Footer"
import Header from "./Header"
import { HeaderOverWrappedProvider } from "../hooks/HeaderOverWrappedContext"

import favicon from "../images/favicon.ico"
import Sidebar from "./Sidebar "

const LayoutWrap = styled.div`
  width: 100%;
  overflow: hidden;
  font-size: 16px;
  color: #292929;
  position: relative;
`

const Layout = ({ children }) => {
  const ref = React.useRef()
  const sideBarRef = React.useRef()
  const [isSidebarOpen, setIsSidebarOpen] = useState(false)

  return (
    <LayoutWrap
      ref={ref}
      isSidebarOpen={isSidebarOpen}
      onClick={e => {
        if (isSidebarOpen && e.target !== sideBarRef.current) {
          setIsSidebarOpen(false)
        }
      }}
    >
      <Helmet>
        <link rel="icon" href={favicon} />
      </Helmet>
      <GlobalStyle />
      <ThemeProvider theme={theme}>
        <HeaderOverWrappedProvider>
          <Header
            isSidebarOpen={isSidebarOpen}
            setIsSidebarOpen={setIsSidebarOpen}
          />
          <main>{children}</main>
          <Footer />
          <Sidebar
            ref={sideBarRef}
            isOpen={isSidebarOpen}
            setIsOpen={setIsSidebarOpen}
          />
        </HeaderOverWrappedProvider>
      </ThemeProvider>
    </LayoutWrap>
  )
}

export default Layout
